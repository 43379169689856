import PocketBase, { LocalAuthStore } from "pocketbase";

import { STORAGE_KEY_PREFIX } from "#composables/use-storage-state";
import { appConfig } from "#config/app";
import { sleep } from "#utilities/sleep";

/** Pocketbase client */
export const pb = new PocketBase(
  appConfig.links.api,
  new LocalAuthStore(`${STORAGE_KEY_PREFIX}-pb-auth`),
);

pb.afterSend = async (_response, data) => {
  // TODO: Only apply fake delay in development
  await sleep(250);

  return data;
};

/** Pocketbase collection names */
export const pbCollectionNames = {
  content: "content",
  composers: "userComposer",
  collaborators: "userCollaborator",
  featureFlags: "featureFlag",
  seasons: "season",
  submissions: "submission",
  reviews: "submissionReview",
} as const;

// TODO: Consider extracting back-expansion (ie. via) into separate type/utility
/**
 * Pocketbase nested expansion fields (names) per collection
 *
 * NOTE: Nested expansion values **must** match PB field names!
 * NOTE: Back-expansion values **must** match expected PB `[collection]_via_[field]` format!
 */
export const pbCollectionExpansions = {
  reviews: ["collaborator", "submission"],
  submissions: ["composer", "season", "submissionReview_via_submission"],
} as const;
